body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    padding: 0px;
    margin: 0px;
    background: rgba(0, 0, 0, 0.05);
    /* font-weight: 400;
    font-size: 12px;
    line-height: 1.5; */
    font-family: "Poppins", sans-serif;
    /* color: #858796;
    text-align: left; */
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}
.mt-4_5,
.my-4_5 {
    margin-top: 1.7rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}
.text-thick{
    font-weight: 400 !important;
}
.text-capitalize{text-transform:capitalize}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #1578a0 !important;
}
.text-danger {
    color: #E93E3A !important;
}
.text-secondary {
    color: #2c2c2c !important;
}

.text-gray {
    color: #636D72 !important;
}
.text-gray-50 {
    color: rgb(0,0,0,.5);
}

.ant-card {
    font-family: "Poppins", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.ant-input-number .ant-input-number-handler-wrap {
    display: none;
}

.cycle-date h5 {
    font-size: 1.2em;
}

.mobile-header-label {
    font-size: 1.35em;
    color: #636d72;
}

.greeting {
    font-size: 1.1rem;
}

.app-container {
    width: 420px;
    margin: 0px auto;
    min-height: 100vh;
    background: #f9f9f9;
    position: relative;
}

.auth-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: rgb(66, 143, 156);
    background: linear-gradient(0deg,
            rgba(67, 144, 156, 1) 0%,
            rgba(56, 122, 156, 1) 100%) !important;
}

.auth-container .auth-header {
    width: 100%;
    flex: auto;
    background: rgb(66, 143, 156);
    background: linear-gradient(0deg,
            rgba(67, 144, 156, 1) 0%,
            rgba(56, 122, 156, 1) 100%) !important;
}

.login-form-inner {
    flex: 0 1 auto;
    width: 100%;
}

.site-layout-content {
    min-height: calc(100vh - 210px);
    padding: 24px;
    background: #fff;
}

.auth-container .site-layout-content {
    min-height: 400px;
}

#components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    /*background: rgba(255, 255, 255, 0.3);*/
}

.ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.ant-layout-content {
    background: #fff;
}

.login-card {
    /* font-weight: 300; */
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
    /* position: absolute; */
    /* width: 360px; */
    /* left: 50%; */
    /* top: 15%; */
    /* transform: translate(-50%, 0); */
}

.login-card button.btn-xl {
    /* font-weight: 300; */
    font-size: 14px;
    height: 46px !important;
}
.gradient-button[disabled], .gradient-button:disabled, button:disabled{
    background: rgba(0,0,0,.2) !important;
    color: gray;
    border: none !important;
}
.logo-default {
    width: 180px;
    margin: auto;
}

.logo-default img {
    max-width: 100%;
}

.list-icon {
    width: 20px;
    margin-right: 10px;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    /*color: pink;*/
    font-size: 14px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 14px;
}

:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px;
}

.ant-col.ant-form-item-label {
    padding: 0px;
}

.ant-col.ant-form-item-label>label {
    /*line-height: 1.6;*/
    /*height: auto;*/
    /*padding-top: calc(0.20rem + 1px);*/
    /*padding-bottom: calc(0.20rem + 1px);*/
}

.ant-form-item {
    /*margin-bottom: 24px;*/
}

.ant-form-item-with-help {
    margin-bottom: 0px;
}
.form-address .ant-form-item-label > label{
    color: rgba(0, 0,0,.7);
    margin-bottom: 6px;
}
.label-input-amount{
    height: auto;
}
.slider-amount .ant-slider-rail{
    border-radius: 20px;
    background-color: #F9F9F9;
}
.slider-amount .ant-form-item-explain-error{
    margin-top: 52px;
    position: absolute;
}
.label-input-amount .ant-form-item-label > label{
    height: auto;
}
.label-input-amount .ant-form-item-control-input{
    /* margin-bottom: -10px; */
    min-height: auto;
    margin-top: 6px;
    margin-bottom: -5px;
}
.label-input-amount .ant-form-item-control-input .ant-input-number-lg input{
    height: auto;
    font-size: 1.3rem;
    font-weight: 600;
}
.ant-row.ant-form-item.label-input-amount{
    margin-bottom: 0;
}
.input-reason.ant-form-item-with-help .ant-form-item-explain{
    min-height: 14px;
    margin-top: 5px;
    margin-left: 3px;
    height: 14px;
}
.input-reason.ant-form-item-has-error .ant-input-status-error{
    border-color: #ff4d4f !important;
}   
.input-xl {
    height: 46px;
}
.carousel-luckydraw{
    cursor: pointer;
    margin-right: 10px;
    width: calc(420px - 100px) !important;
}
.carousel-luckydraw.vertical{
    width: 100% !important;
    margin-bottom: 20px;
}
.carousel-luckydraw.vertical a > img{
    border-radius: 8px;
}
.input-default {
    border-radius: 6px;
}
.ant-input-number-affix-wrapper.input-amount{
    
}
.input-default.input-amount{
    border: none;
    box-shadow: none;
    text-indent: 0;
    padding: 0;
    height: auto;
    /* margin-top: px; */
}
.ant-input-number-affix-wrapper-disabled.input-amount, .ant-input-number-affix-wrapper-disabled.input-amount .ant-input-number-disabled{
    border: none;
    box-shadow: none;
    background: none !important;
    /* margin-top: px; */
}


.input-gray {
    background: #f2f2f2;
    border-color: transparent;
}

.button-default-style {
    border-radius: 8px;
}

.button-default-orange {
    background: #ed8c24;
    border-color: #e38d30;
}

.button-default-orange:hover,
.button-default-orange:focus {
    background: #faa243;
    border-color: #ed9639;
}
.btn-reason{
    color: rgba(0, 0, 0, .5);
}
.btn-reason.active{
    /* background-color: #40a9ff; */
    color: #40a9ff !important;
    border-color: #40a9ff !important;
}

.tab-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 420px;
    background: #fff;
    /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.05); */
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    
    margin: 0 auto;
}

.tab-bar-item {
    padding-top: 14px;
    padding-bottom: 14px;
    position: relative;
    display: block;
    color: #212529;
}

.tab-bar-title {
    font-size: 12px;
    /* color: rgba(0, 0, 0, .5); */
    color: #B9C5CE;
    font-weight: 400;
    margin-top: 12px;
    /* padding-top: 10px; */
    display: block;
}

.tab-bar-icon {
    margin-bottom: -6px;
}

.selected-tab {
    color: #1890ff;
}
.selected-tab .tab-bar-title{
    color: #1890ff;
}

.app-header img {
    width: 100px;
}

.app-header .header-text {
    color: white;
}

.header-back-button {
    position: absolute;
    left: 18px;
    background: none;
    border: none;
}

.header-group {
    /* position: absolute; */
    right: 10px;
    padding-right: 1rem;
    top: 2.9%;
    justify-content: space-between;
}


.no-border {
    border: none !important;
}

.theme-background {
    background: linear-gradient(180deg, #1578a0 0%, #15929e 100%);
}

.background-transparent {
    background-color: transparent;
}

.app-header {
    position: fixed;
    top: 0;
    /* left: 0;
    right: 0; */
    width: 420px;
    height: 75px;
    background: rgba(23, 88, 139, 1);
    background: linear-gradient(180deg, #1578a0 0%, #15929e 100%);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.app-header-white {
    position: fixed;
    top: 0;
    /* left: 0;
    right: 0; */
    width: 420px;
    height: 75px;
    background: rgb(255, 255, 255);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.main-wrapper {
    padding: 10px 10px;
    overflow: hidden;
}

/* chart */
.single-chart {
    width: 100%;
    justify-content: space-around;
}

.circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 200px;
    max-height: 250px;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    -webkit-animation: progress 1s ease-out forwards;
            animation: progress 1s ease-out forwards;
}

.camera-container{
    background-color: black;
    height: 100vh;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    /* flex */
}
.camera-container > div{
    /* flex: 5; */
   
}
.camera-body{
    flex: 7 1;
    overflow: hidden;
    /* vertical-align: center; */
    position: relative;

}
.camera-body video{
    width: 100%;
    height: 100%;
}
.camera-body > img{
    max-width: 120%;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    min-width: 50%;
    min-height: 50%;
}
.camera-footer, .camera-header {
    /* border: 1px solid red; */
    height: 30px;
    flex: 1 1;
}
.camera-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* align-content: center; */
}
.capture-button{
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}
.modal-body-pd-0 .ant-drawer-body{
    padding: 0px;
}

#days-count {
    font-size: 0.15rem;
}

@-webkit-keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.circular-chart.orange .circle {
    stroke: #ff9f00;
}

.circular-chart.green .circle {
    stroke: #4cc790;
}

.circular-chart.blue .circle {
    stroke: #3c9ee5;
}

.percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.3em;
    text-anchor: middle;
}

/*end of chart*/
.mt60 {
    margin-top: 65px;
}

.mb60 {
    margin-bottom: 260px !important;
}

.pb70 {
    padding-bottom: 70px !important;
}

.app-card {
    /* border-radius: 0.5rem; */
    border-top: 4px solid #f9f9f9;
}

.round-0 {
    border-radius: 0 !important;
}

.btn-app {
    border-radius: 0.5rem;
    line-height: 1.75rem;
    font-size: 1rem;
    height: auto;
}

.btn-block {
    width: 100%;
}

.btn-app-primary {
    /* background: linear-gradient(0deg, rgba(32, 182, 182, 1) 0%, rgba(23, 88, 139, .9) 100%); */
    /* background-image: linear-gradient(0deg, rgb(32, 182, 182) 0%, rgba(23, 88, 139, 0.9) 100%); */
    /* background-image: linear-gradient(0deg, rgb(75 166 177) 0%, rgba(23, 88, 139, 0.9) 100%); */
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    border: none;
    background: rgb(55, 119, 156);
}

.btn-app-primary:hover,
.btn-app-primary:focus {
    background: linear-gradient(81deg,
            rgba(32, 182, 182, 1) 0%,
            rgba(23, 88, 139, 0.9) 100%);
    background-image: linear-gradient(81deg,
            rgb(32, 182, 182) 0%,
            rgba(23, 88, 139, 0.9) 100%);

    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    border: none;
}

.btn-app-danger {
    background: #ff5757;
    border-color: #e3342f;
    color: white;
}

.btn-app-danger:hover,
.btn-app-danger:focus {
    background: #ff5757;
    border-color: #e3342f;
    color: white;
}

.custom-shadow {
    box-shadow: 0 0 6px rgb(0 0 0 / 1%);
}

.app-container.with-background {
    background-repeat: no-repeat;
    background-position: 0 -140px;
}

.mobile-header-label {
    font-size: 1.2rem;
}

.app-account-header {
    height: calc(80vh / 2.75);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.app-account-header .user-image {
    width: 100px;
    height: 100px;
    background: #f5f5f5;
    border-radius: 50%;
}

.auth-container h2 {
    font-size: 1.3rem;
}

.app-account-header .item {
    color: white;
    font-size: 0.9rem;
    padding-top: 10px;
}

.app-account-header .join-date {
    padding-top: 0px;
    font-size: 0.9rem;
}

.content-list-account {
    list-style: none;
    padding: 0px;
    margin-bottom: 32px;
    background: white;
}

.content-list-account li {
    /* border-bottom: 1px solid rgba(0, 0, 0, .1); */
    padding: 15px 0px;
    /* margin-left: 15px;
    margin-right: 15px; */
    font-size: 0.9rem;
}

.content-list-account li.border-top {
    /*border-top: 1px solid rgba(0,0,0,.1) !important;*/
}

.content-list-data {
    list-style: none;
    padding: 0;
    background: white;
}

.text-white {
    color: white;
}

.text-Nevada {
    color: white;
}

.content-list-account li a {
    text-decoration: none;
    /*color: rgba(0,0,0,.6);*/
    color: black !important;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
}

.content-list-account li a>* {
    cursor: pointer;
}

.content-list-account li a label {
    margin: 0px;
}

.content-list-account li a label.list-account-icon {
    width: 32px;
}

.content-list-account li a label.right {
    margin-left: auto;
}

.content-list-history {
    background: #f9f9f9;
    list-style: none;
    padding: 0px;
    padding-top: 5px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: -10px;
    margin-left: -10px;
}

.content-list-history li {
    /* border: 1px solid #b9c5ce; */
    /* border-radius: 8px; */
    /* box-shadow: rgba(149, 157, 165, 0.1) 0px 3px 10px; */
    /* margin: 10px 10px; */
    margin-bottom: 7px;
    padding: 12px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
}

.content-list-history li .icon {
    width: 92px;
    font-size: 2.2rem;
    color: rgba(23, 88, 139, 1);
}

.content-list-history li .item-detail {
    padding: 0px 10px;
    line-height: 1rem;
    flex: 1 1;
}

.content-list-history li .item-detail .date {
    margin-top: 8px;
    color: #666;
}

.content-list-history li .status-row {
    margin-right: 10px;
}

.content-list-history li .item-detail .product-name {
    color: #2c2c2c;
    margin-top: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}
.categories-container{
    margin-left: -6px;
    margin-right: -6px;
}

.categories-container .category-item{
    flex: 1 1 40%;
    margin: 6px;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.15) 0px 3px 10px;
}
.categories-container .category-item img{
    margin-top: 10px;
}
.categories-container .category-bacground{
    background: white;
}
.categories-container .category-name{
    align-self: center;
    margin: 0px;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 1.2;
    color: rgba(0,0,0,1);
}

.content-list-history li .item-detail .status {}

.content-list-history li .item-detail .amount {
    color: #1578A0;
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.content-list-history li .angle {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.1);
    width: 32px;
}

.badge {
    background: #d1d1d1;
    padding: 7px 12px;
    border-radius: 30px;
    padding-left: 20px;
    position: relative;
    font-size: 10px;
}

.badge:before {
    content: " ";
    position: absolute;
    width: 5px;
    border-radius: 50%;
    height: 5px;
    top: 11px;
    left: 10px;
}

.badge.badge-success {}

.badge.badge-warning {
    background: #fbf6c7;
    color: #b59d1c;
}
.badge.badge-blue-500 {
    background: #dbe6fc;
    color: #686997;
}
.badge.badge-blue-500:before {
    background: #1c4eb5;
}

.badge.badge-warning:before {
    background: #b59d1c;
}

.badge.badge-danger {
    background: #ffdcdc;
    color: #9d2828;
}

.badge.badge-danger:before {
    background: #9d2828;
}

.badge.badge-success {
    background: #dcf3d4;
    color: #206a1f;
}

.badge.badge-success:before {
    background: #206a1f;
}
.badge.badge-light {
    background: #e8e8e8;
    color: #343434;
}
.badge.badge-light:before {
    background: #343434;
}

.badge.badge-info {
    background: #ffe3cc;
    color: #b77567;
}

.badge.badge-info:before {
    background: #b77567;
}

.modal-overlay {
    z-index: 100;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 420px;
    top: 0px;
    bottom: 0px;
    margin-left: -18px;
}

.custom-confirm {}

.custom-confirm .ant-modal-body {
    padding-left: 15px;
    padding-right: 15px;
}

.custom-confirm .ant-modal-confirm-body {
    display: flex;
    flex-direction: column;
}

.custom-confirm .ant-modal-confirm-body .anticon,
.custom-confirm .ant-modal-confirm-body .ant-modal-confirm-title {
    text-align: center;
}

.custom-confirm .ant-modal-confirm-body .anticon,
.custom-confirm .ant-modal-confirm-body .ant-modal-confirm-title,
.custom-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
    text-align: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.custom-confirm .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 1.2rem;
}
.search-suggestion-container{
    position: fixed;
    top: 60px;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    /* bottom: 0px; */
    margin: auto;
    background: white;
    width: 420px;
    z-index: 5;
    display: none;
    /* transition:  all 5s; */
    /* transition: bottom 2s linear; */
}
.search-suggestion-container.show{
    /* transition: bottom 2s linear; */
    /* transition:  all 5s; */
    bottom: 0px;
    display: block;
}

table {
    font-size: medium;
}

.custom-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
    font-size: 0.95rem;
}

.custom-confirm .ant-modal-confirm-btns {
    float: none;
    display: flex;
    flex-direction: column-reverse;
}

.custom-confirm .ant-modal-confirm-btns .ant-btn {
    /*display: block;*/
    /*width: 100%;*/
    margin: 0px !important;
    margin-top: 10px !important;
}

.custom-confirm .ant-modal-confirm-btns .ant-btn:last-child {
    margin-top: 0 !important;
}

/* Card image loading */
.card__image {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.card__image.loading {
    height: 300px;
    /*width: 400px;*/
    width: 100%;
}
.card__image__medium.loading {
    height: 150px;
    /*width: 400px;*/
    width: 100%;
}
/* Card title */
.card__title {
    /*padding: 8px;*/
    font-size: 22px;
    font-weight: 700;
}

.card__title.loading {
    height: 2rem;
    width: 100%;
    /*margin: 1rem;*/
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 3px;
}

.card__title.medium.loading {
    height: 1.5rem;
}

.card__title.small.loading {
    height: 1rem;
}

/* Card description */
.card__description {
    padding: 8px;
    font-size: 16px;
}

.card__description.loading {
    height: 3rem;
    margin: 1rem;
    border-radius: 3px;
}

/* The loading Class */
.loading {
    position: relative;
    overflow: hidden;
    background-color: #e2e2e2;
}

/* The moving element */
.loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);

    background: linear-gradient(90deg,
            transparent,
            rgba(255, 255, 255, 0.5),
            transparent);

    /* Adding animation */
    -webkit-animation: loading 1s infinite;
            animation: loading 1s infinite;
}

/* Loading Animation */
@-webkit-keyframes loading {
    100% {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}
@keyframes loading {
    100% {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}
.shine {
    background: #f2f3f4;
    background-image: linear-gradient(to right, #f2f3f4 0%, #edeef1 20%, #f2f3f4 40%, #f2f3f4 100%);
    background-repeat: no-repeat;
    background-size: 800px 420px; 
    display: inline-block;
    position: relative; 
    animation-duration: 1s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    border-radius: 8px;
}
  
.shine.medium {
    display: block!important;
    width: 100%; 
    height: 130px; 
}
.shine.x-large {
    display: block!important;
    width: 100%; 
    height: 420px; 
}
@-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }
@keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.table {
    width: 100%;
}

.table th,
.table td {
    padding: 0.5rem;
    vertical-align: top;
    /*border-top: 1px solid #dee2e6;*/
}

.pincode-input-container .pincode-input-text {
    padding: 0 !important;
    margin: 0 2px;
    text-align: center;
    border: 1px solid;
    background: transparent;
    width: 50px;
    height: 50px;
}

.pincode-input-container .pincode-input-text:focus {
    outline: none;
    box-shadow: none;
}

.ant-drawer.ant-drawer-bottom.mobile-drawer {
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    display: flex;
    justify-content: center;
    max-width: 420px;
}

.ant-drawer.ant-drawer-bottom.ant-drawer-open.mobile-drawer>.ant-drawer-content-wrapper {
    max-width: 420px;
}
.d-flex {
display: flex !important;
}

.d-inline-flex {
display: inline-flex !important;
}
.flex-row {
flex-direction: row !important;
}

.flex-column {
flex-direction: column !important;
}

.flex-row-reverse {
flex-direction: row-reverse !important;
}

.flex-column-reverse {
flex-direction: column-reverse !important;
}

.flex-wrap {
flex-wrap: wrap !important;
}

.flex-nowrap {
flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
flex-wrap: wrap-reverse !important;
}

.flex-fill {
flex: 1 1 auto !important;
}

.flex-grow-0 {
flex-grow: 0 !important;
}

.flex-grow-1 {
flex-grow: 1 !important;
}

.flex-shrink-0 {
flex-shrink: 0 !important;
}

.flex-shrink-1 {
flex-shrink: 1 !important;
}

.justify-content-start {
justify-content: flex-start !important;
}

.justify-content-end {
justify-content: flex-end !important;
}

.justify-content-center {
justify-content: center !important;
}

.justify-content-between {
justify-content: space-between !important;
}

.justify-content-around {
justify-content: space-around !important;
}

.align-items-start {
align-items: flex-start !important;
}

.align-items-end {
align-items: flex-end !important;
}

.align-items-center {
align-items: center !important;
}

.align-items-baseline {
align-items: baseline !important;
}

.align-items-stretch {
align-items: stretch !important;
}

.align-content-start {
align-content: flex-start !important;
}

.align-content-end {
align-content: flex-end !important;
}

.align-content-center {
align-content: center !important;
}

.align-content-between {
align-content: space-between !important;
}

.align-content-around {
align-content: space-around !important;
}

.align-content-stretch {
align-content: stretch !important;
}

.align-self-auto {
align-self: auto !important;
}

.align-self-start {
align-self: flex-start !important;
}

.align-self-end {
align-self: flex-end !important;
}

.align-self-center {
align-self: center !important;
}

.align-self-baseline {
align-self: baseline !important;
}

.align-self-stretch {
align-self: stretch !important;
}
  
@media (max-width: 420px) {
    body {
        background: #fff;
    }

    .app-header {
        left: 0;
        width: 100%;
    }

    .app-header-white {
        left: 0;
        width: 100%;
    }

    /* .app-container {
        width: 100%;
        margin: 0px auto;
        background: white; */
    /*min-height: calc(100vh);*/
    /* } */

    .tab-bar {
        width: 100%;
        left: 0px;
        right: 0px;
    }

    .ant-drawer.ant-drawer-bottom.mobile-drawer {
        width: 100%;
    }
    
   
    .ant-drawer.ant-drawer-bottom.ant-drawer-open.mobile-drawer>.ant-drawer-content-wrapper {
        width: 100%;
    }
}

.wrapper {
    padding: 10px;
    /* border-radius: 10px; */
    border-radius: 16px 16px 0px 0px;
}

.buttonBox_ {
    /* width: 100%; */
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    /* width: 250px; */
    /* max-width: 260px; */
    margin: auto;
    /* height: calc(100% - 110px); */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(auto-fill, 60px); */
    grid-gap: 15px;
    justify-content: center;
}

.buttonBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.numb-button_ {
    aspect-ratio: 1 / 1;
    object-fit: contain;
    border-radius: 50%;
    border: none;
    /* background-color: rgba(0, 0, 0, .1); */
    background: none;
    /* background: red; */
    font-size: 24px;
    /* color: rgb(255, 255, 255); */
    /* font-weight: bold; */
    cursor: pointer;
    /* border-radius: 10px; */
    outline: none;
}

.numb-button {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 10px;
    background: none;
    border: none;
    font-size: 24px;
}

.numb-button.back {
    font-size: 10px;
}

.numb-button:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.equals {
    grid-column: 3 / 5;
    background-color: rgb(243, 61, 29);
}

.equals:hover {
    background-color: rgb(228, 39, 15);
}

.pin-value-container {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-bottom: 10px; */
}

.pin-value-item {
    margin: 5px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    color: transparent !important;
    text-shadow: none !important;
    border: none;
    outline: none;
    caret-color: transparent;
}

.otp-value-item {
    margin: 5px;
    width: 46px;
    height: 46px;
    box-shadow: none !important;
    text-align: center;
    border: none;
    outline: none;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    text-shadow: none !important;
    border: none;
    outline: none;
    caret-color: transparent;
}

.pin-value-item.filled {
    background-color: black;
}

.loading-page-container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(255, 255, 255, 1);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.font-title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.font-subtitle {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.font-content {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.font-content-semibold {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.font-content-semibold-50 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #000000;
}
.text-12{
    font-size: 12px;
}
.text-10{
    font-size: 10px !important;
}
.text-9{
    font-size: 9px !important;
}
.text-8{
    font-size: 8px !important;
}


.text-bold-500{
    font-weight: 500;
}

.text-bold-700{
    font-weight: 700;
}
.font-content-medium {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.font-subtext-medium {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.font-subtext {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.font-small-text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #000000;
}
.feature-additional-icon{
    
        max-width: 40%;
        position: absolute;
        top: -8px;
        left: -8px;
        z-index: 1;
    
}
.font-SubItem-12 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #000000;
}

.font-balance {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #1578a0;
}

.border-radius {
    border-radius: 8px;
}

.custom-modal .ant-modal-content {
    border-radius: 16px 16px 0px 0px;
    position: fixed;
    bottom: 0;
    right: 33%;
    width: 420px;
    height: 70vh;
}

.custom-card-body .ant-card-body {
    padding: 10px;
}
.card-detail-history{
    margin: .5rem -1rem;
    border: none;
}
.header-icon .ant-badge {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    left: 72%;
}

.no-border {
    border: none;
}

/* page login */
.login-card {
    /* height: 40vh; */
    margin: 16px;
}

.header-login {
    /* height: 58vh; */
    flex: 1 1;
    background: rgba(23, 88, 139, 1);
    background: linear-gradient(180deg, #1578a0 0%, #15929e 100%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header_Title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
}

.banner-login {
    width: 55%;
}

/* page withdraw */

.font-days {
    position: absolute;
    top: 18%;
    left: 39%;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #1578a0;
}

.font-desc {
    position: absolute;
    top: 33%;
    left: 37%;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
}

.pb77 {
    padding-top: 77px;
}

/* page account */
.bg-gradient {
    background: linear-gradient(180deg, #1578a0 0%, #15929e 145.68%);
}
.bg-white{
    background-color: white !important;
}
.bg-white-50{
    background-color: rgba(255, 255, 255,.5) !important;
}
.badge-notif {
    position: relative;
}

.badge-notif[data-badge]:after {
    content: attr(data-badge);
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 0.7em;
    background: #e53935;
    color: white;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
}

.content-list-notification {
    background: #f9f9f9;
    list-style: none;
    padding: 5px 0px;
}

.content-list-notification li {
    margin: 5px 0px;
    padding: 12px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
}

/* page withdraw */

.ant-slider-rail {
    height: 10px;
    background-color: #B9C5CE;
}

/* page groceries */
.header-groceries {
    position: fixed;
    top: 0;
    width: 420px;
    height: 81px;
    background: #fff;
    color: #B9C5CE;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}

.header-back-graceries {
    /* position: absolute; */
    /* left: 3px; */
    background: none;
    border: none;
    margin-left: 3px;
   
    
}

.search-container{
    /* width: 100%; */
    flex-grow: 1;
    padding-right: 20px;
    position: relative;
    
}
.search-container .search-button{
    right: 24px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    position: absolute;
    background: none;
    /* top: 0px; */
    border: none;
    cursor: pointer;
}
.search-container .search-button img{
    max-width: 100%;
    width: 24px;
    /* display: block; */
}
.search-container .input-search, .search-container .input-search:focus{
    padding-right: 36px;
    text-indent: 8px;
    border: 1px solid rgba(0,0,0,.1);
    outline: none;
    box-shadow: none;
    color: #000000;
}
.search-container .input-search{
    width: 100%;
    display: block;
    height: 46px;
    border-radius: 10px;
}
.button-as-input{
    margin-top: 10px;
    position: relative;
}
.button-as-input button{
    padding-top: 8px !important;
    padding-right: 30px;
    padding-left: 15px;
    padding-bottom: 8px;
    display: block;
    width: 100%;
    text-align: left;
    background: none;
    border: 1px solid #B9C5CE;
    border-radius: 8px;
    color: rgba(0, 0, 0, .7);
    font-size: 1rem;
    min-height: 40px;
    font-weight: 300;
    font-size: .8rem;
}


.drawer-bottom-action .ant-drawer-body{
    display: flex;
    flex-direction: column;
}
.ant-drawer-no-padding .ant-drawer-body{
    padding: 0 !important;
}
/* .address-list-bottom-sheet{
    display: flex;
    flex-direction: column;
} */
.address-list-bottom-sheet > div{
    /* border: 1px solid red; */
}
.address-list-bottom-sheet > div.bottom-sheet-action{
    /* border: 1px solid red; */
}
.default-input-app{
    height: 42px;
    margin-bottom: 20px;
    border-radius: 8px;
}
 .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{

    height: 42px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 42px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    margin-top: 5px;
}
.privacy-container.justify h1{
    
    font-size: 1.4rem;
}
.privacy-container.justify h2{
    font-size: 1.2rem;
}
.privacy-container.justify > p, .justify > ul > li {
    text-align: justify;
}
/* .default-input-app .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
} */
.custom-label-input{
    margin-bottom: 7px;
    display: block;
    color: rgba(0, 0, 0, .7);
}
.btn.list-address{
    display: flex;
    flex-direction: column;
    /* border: 1px solid #e1e4e7; */
    border: none;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 5px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    background: none;
    padding: 15px 15px;
    text-align: left;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, .7);
    position: relative;
}
.btn.list-address.active{
    border: 1px solid #15929e;
}
.btn.list-address.active::before{
    border-radius: 0 6px 6px 0;
    content: "";
    width: 6px;
    height: 30px;
    background-color: #15929e;
    position: absolute;
    top: 10px;
    left: 0px;
}
.btn.list-address label, .btn.list-address p{
    font-weight: 300;
    font-size: .8rem;
    
}
.btn.list-address label.receiver{
    font-weight: 600;
    color: rgba(0, 0, 0, .7);
    margin-bottom: 10px;
}
.btn.list-address label{
   
}
.btn.list-address p{
    
   margin-top: 20px;
}

.button-as-input .caret{
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
}
.image-corousel {
    width: 100%;
    height: 144px;
    margin: 10px 20px;
}

.slick-slide img {
    width: 100%;
    height: 144px;
    margin: auto;
    border-radius: 8px;
}

.kategori-list {
    margin-top: 30px;
    margin-bottom: 10px;
}

.kategori-list .item-kategori {
    background: #fff;
    border-radius: 8px;
    height: 64px;
}

.icon-kategori {
    position: absolute;
    width: 72px;
    height: 36px;
    left: 0;
    top: 43%;
    opacity: 0.6;
    border-radius: 0px 2523px 0px 656px;
}

.label-kategori {
    position: absolute;
    left: 50%;
    top: 26px;
    text-align: left;
}

.bg-icon-kategori-1 {
    background: linear-gradient(140.65deg, #F3903F 3.29%, rgba(243, 144, 63, 0) 99%);
}

.bg-icon-kategori-2 {
    background: linear-gradient(146.31deg, #4792A1 13.96%, rgba(217, 231, 223, 0.22) 114.17%);
}

.bg-icon-kategori-3 {
    background: linear-gradient(143.34deg, #00A86B 13.08%, rgba(113, 239, 193, 0.28) 92.89%);
}

.bg-icon-kategori-4 {
    background: linear-gradient(140.65deg, #FDC70C 3.29%, rgba(253, 199, 12, 0) 99%);
}

.product-seller{
    margin:25px auto;
    height: 16,2rem;
}

.card-product {
    position: relative;
    width: 158px;
    height: 165px;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 8px;
    padding: 10px;
}

.card-product .img-product {
    width: 40%;
    height: 50%;
}

.card-product .title-product {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;

    /* Black */
    color: #2C2C2C;
}

.card-product .price-product {
    /* Mobile/Content Semibold */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    /* Gradien 2 */
    background: linear-gradient(180deg, #1578A0 0%, #15929E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.card-product .disc-product {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    color: #BDBDBD;
}

.slick-slider.custom-slider .slick-slide {
    /* margin-left:10px; */
}

.slick-slider.custom-slider  .slick-list {
    /* margin-left:-35px; */
}
.css-ybny3o .slick-dots{
    left: 0 !important;
}
.abcdef{
    margin-right: 10px;
    width: calc(420px / 2.5) !important;
}
.btn-cart-container{
    width: 420px;
    position: fixed;
    margin: auto; 
    border: 1px solid red;
    bottom: -2px
}

.btn-cart {
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 48px;
    /* background: linear-gradient(180deg, #1578A0 0%, #15929E 100%); */
    position: absolute;
    right: 15px;
    bottom: 15px;
    padding: 0;
}
.btn-cart img {
    /* -webkit-filter: brightness(0) invert(1); */
    /* filter: brightness(0) invert(1); */
}

/* page list product */
.list-filter {
    display: flex;
    list-style: none;
    overflow-x: auto;
    white-space: nowrap;
    margin: 10px auto;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
}

.list-filter::-webkit-scrollbar {
    display: none;
}

.filter-option{
    margin:auto 10px;
}

.filter-icon{
    margin-right:10px;
    height: 23px;
}

.section-product {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    /* align-items: flex-start; */
    align-items: stretch;
    margin: 10px 0;
}

.item-product {
    width:  47.55555%;
    margin: 4px;
    /* flex: 5 1 49%; */
    /* max-width: 50%; */
    /* width: 42%; */
    /* margin: 14px; */
    box-sizing: border-box;
    float: left;
    border-radius: 6px;
    /* border-top-right-radius: 3px; */
    /* border-bottom-right-radius: 3px; */
    padding: 10px;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 5%); */
    box-shadow: rgba(149, 157, 165, 0.1) 0px 0px 10px 2px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
    transition: .4s;
    background-color: #FFFFFF;
}

.square-image-container{
    position: relative;
    width: 100%;
    display: block;
    padding-top: 100%;
}
.product-img{
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    border-radius: 6px;
    /* padding-top: 100; */
    /* width: 100px !important; */
    /* height: 100px !important; */
    /* text-align: center !important; */
    /* margin: 0 auto !important; */
    /* display: block !important; */
}

.product-desc{
    /* height: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.sku-name-container{
    height: calc(1.6em * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.sku-name-container-3{
    /* height: calc(1.6em * 3); */
    height: calc(1.6em * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    /* -webkit-line-clamp: 3; */
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
/* detail prodcut */
.detail{
    margin-top:10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
}
.detail-image {
    margin: 0 auto;
    position: relative;
}
.detail-image img{
    max-width: 100%;
    border-radius: 6px;
}
.empty-stock{
    position: absolute;
    left: 50%;
    top: 50%;
    background: rgba(0,0,0,.6);
    font-size: 1.5rem;
    padding: 6px 12px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 30px;
    color: rgba(255,255,255, .7);
}
.detail-price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px 0px;
}
.detail-desc {
    text-align: justify;
}
.font-detail-price {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}
.detail-footer {
    position: fixed;
    width: 420px;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-top: 1px solid #F9F9F9;
}
.detail-footer-cart {
    padding: 10px;
}

/* button outline */
.button {
    min-width: 100px;
    background: linear-gradient(180deg, #1578A0 0%, #15929E 100%);
    border-radius: 4px;
    border: none;
    outline: none;
    color:#fff;
    padding: 10px 30px;
    text-align: center;
    cursor: pointer;
}
.button:hover{
    opacity: 80%;
}

.button-outline {
    min-width: 100px;
    padding: 10px 30px;
    border: none;
    outline: none;
    position: relative;
    border-radius: 4px;
    background: linear-gradient(180deg, #1578A0 0%, #15929E 100%);
    cursor: pointer;
    z-index: 1;
}
.button-outline::before {
    content: "";
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background-color: white;
    border-radius: 4px;
    z-index: -1;
    transition: 200ms
}
.button-outline:disabled::before {
   
    background-color: rgba(0,0,0,.03) !important;
}
.button-outline:disabled::after{
    background: none;
    color: gray;

}
.button-outline::after {
    content: attr(data);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    background: linear-gradient(180deg, #1578A0 0%, #15929E 100%);
    -webkit-background-clip: text;
    color: transparent;
    transition: 200ms
}
.button-outline:hover::before {
    opacity: 50%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.button-outline:hover::after{
    color: white;
}

/* page cart */
.cart-card {
    /* position: relative; */
    display: flex;
    background: #FFFFFF;
    margin-top: 3px;
    border-bottom: 1px solid #f9f9f9;
    flex: 0 1 1;
}

.cart-card-image {
    display: flex;
    /* justify-content: center; */
    /* justify-items: center; */
    /* align-content: center; */
    align-items: center;
    justify-content: center;
    /* width: 130px; */
    flex: 1 1;
    padding: 5px;
    /* position: absolute; */
    left: 0;
    position: relative;
   
}

.cart-card-image img {
    max-width: 100px;
    /* width: 100px; */
}

.cart-card-body {
    display: flex;
    flex-direction: column;
    /* margin-left: 110px; */
    padding: 21px 25px 21px 10px;
    flex: 2 1;
    /* flex-grow: 1; */
    
}
.list-button{
    padding: 0;
    list-style: none;
}
.list-button li{
    /* margin-bottom: 10px; */
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    position: relative;
    display: block;
}
.list-button li:last-child{
    border-bottom: none;
    margin-bottom: 30px;
}

.list-button li button{
    background: none !important;
    padding-bottom: 9px;
    padding-top: 9px;
    background-color: none !important;
    border: none !important;
    outline: none !important;
    display: block !important;
    width: 100%;
    text-align: left;
}
.button-group {
    display: flex;
    justify-content: space-between;
}
.button-group input {
    border: none;
    text-align: center;
    width: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.pac-container{
    display: block;
    width: 100%;
    padding: 10px;
}
.pac-target-input{
    width: 100%;
    height: 38px;
    border:1px solid rgba(0,0,0,.4);
    text-indent: 10px;
    border-radius: 10px;
    outline: none;

}
.btnIcon {
    border: none;
    margin: 2px;
    background: transparent;
    border: 2px solid #1578A0;
    border-radius: 8px;
}
.btnIconSmall {
    font-weight: 500;
    width: 28px;
    height: 28px;
    line-height: 0;
    background: transparent;
    border: 2px solid #1578A0;
    border-radius: 8px;
    text-align: center;
    padding: 0;
}
.btnIcon img {
    width: 24px;
    height: 24px;
    margin-left: -10px;
}
.btnBorder {
    border: 2px solid #B9C5CE;
}
.footer {
    position: fixed;
    /* width: calc(100% - 350px); */
    width: 100%;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-top: 1px solid #F9F9F9;
}

/* page checkout */
.section-address {
    left: 0;
    right: 0;
    background: #FFFFFF;
    padding: 20px;
    margin: auto -13px;
}
.section-products {
    left: 0;
    right: 0;
    background: #fff;
    padding: 20px;
    margin: auto -13px;
    margin-top: 5px;
}
.section-pengiriman {
    left: 0;
    right: 0;
    background: #FFFFFF;
    padding: 20px;
    margin: auto -13px;
    margin-top: 5px;
}
.section-rincian {
    left: 0;
    right: 0;
    background: #FFFFFF;
    padding: 20px;
    margin: auto -13px;
    margin-top: 5px;
    height: auto;
}
.product-table{
    width: 100%;
    padding: 10px;
}
td:last-child {
    padding-left: 10px;
    display: flex;
    flex-direction:column;
    align-content: space-between;
}

/* page payment */
.total-cart{
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* align-content: space-between; */
    /* justify-content: space-between; */
    background:#FFFFFF;
}
.total-cart > div{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
}

.payment{
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
}

.list-payment {
    margin:10px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    /* width: 100%; */
}

.item-payment {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 10px;
    flex: 1 1;
    text-align: left;
    padding: 8px;
    line-height: 30px;
    list-style: none;
    background-color: #FFFFFF;
    /* border: 1px solid #1578A0; */
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 8px;
}
.item-payment * {

    cursor: pointer;
}
.item-payment.active {
    border-color: #1578A0;
}
.item-payment.inactive {
    /* border-color: #1578A0; */
    opacity: .4;
}

.img-payment{
    width: 35%;
    max-width: 100px;
    /* height: 22%; */
    margin-right: 5px;
    padding: 10px;
}
.desc-payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}
.footer-payment {
    position: fixed;
    /* width: calc(100% - 350px); */
    width: 100%;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-top: 1px solid #F9F9F9;
}

.locations-modal-container{
    padding: 0;
    list-style: none;
}
.locations-modal-container li{ 
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 8px;
    /* box-shadow: rgb(99 99 99 / 15%) 0px 2px 8px 0px; */
    border:1px solid #dee3e4;
    border-radius: 8px;
    background: none;
}
.locations-modal-container li p{
    margin: 0;
    font-size: 1rem;
}
.locations-modal-container li p:last-child{
    color: #666666;
    font-size: 13px;
}
@media screen and (max-width: 480px) {
    .app-container{
        width: 100%;
        height: auto;
    }
    .search-container .search-button{
        right: 20px;
        
    }
    .custom-modal .ant-modal-content {
        border-radius: 16px 16px 0px 0px;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        padding: 0 auto;
        height: 70vh;
        overflow-x: scroll;
    }

    .ant-drawer-content-wrapper .ant-drawer-content {
        width: 100%;
        border-radius: 16px 16px 0px 0px;
    }
    .detail-footer {
        width: 100%;
    }
    .header-groceries{
        width: 100%;
    }
    .header-groceries .ant-input-group-wrapper{
        margin-left: 12px;
    }
    .btn-cart-container{
        max-width: 100%;
        
    }
}
